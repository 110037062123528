<template>
  <div class="user-management-container">
    <nxp-search
      :config-list="searchConfigList"
      @clickSearch="clickSearch"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <select-table
      :accessories="nxpTabelAccessories"
      only-key-id="id"
      :show-pagination="true"
      :table-columns="tableColumns"
      :table-data="tableData"
      :table-loading="tableLoading"
      :w-theight="320"
      @fetchData="fetchData"
    />
  </div>
</template>

<script>
import {
  queryReportDataList,
  againCreateReport,
} from "@/project/eztree/baseApi/commonApi";
import { getArrSort } from "@/project/eztree/baseTool/mapStr";
// 成团状态：1,进行中，2已成团，3已终止
const statusOptions = [
  { label: "失败", value: "0" },
  { label: "生成中", value: "1" },
  { label: "已生成", value: "2" },
];
export default {
  name: "Report",
  components: {
    addEdit: () => import("./addEdit"),
  },
  props: {
    project_id: {
      type: String,
    },
    userPhone: {
      type: String,
    },
  },
  data() {
    return {
      statusOptions: statusOptions,
      queryForm: {},
      searchConfigList: [
        {
          type: "text",
          showName: false,
          model: "",
          param: "phone",
          label: "手机号",
        },
        {
          type: "daterange",
          showName: false,
          model: "",
          param: "date",
          label: "日期",
        },
      ],
      tableLoading: false,
      tableData: [],
      nxpTabelAccessories: {
        pageType: "",
        pageNo: 1,
        pageSize: 999999,
        total: 0,
        height: this.$baseTableHeight(1),
      },
    };
  },
  computed: {
    tableColumns() {
      let columns = [
        {
          label: "苗店手机号",
          prop: "shop_phone",
          width: "160px",
        },
        {
          label: "苗店名称",
          prop: "shop_name",
        },
        {
          label: "项目名称",
          prop: "project_name",
        },
        { label: "报告名称", prop: "report_name" },
        {
          label: "状态",
          prop: "status",
          width: "100px",
          render: (h, params) => {
            return h(
              "el-tag",
              {
                attrs: {
                  type:
                    params.row.status == "0"
                      ? "danger"
                      : params.row.status == "1"
                      ? "warn"
                      : params.row.status == "2"
                      ? "success"
                      : "",
                  effect: "plain",
                },
              },
              params.row.status == "0"
                ? "失败"
                : params.row.status == "1"
                ? "生成中"
                : params.row.status == "2"
                ? "已生成"
                : ""
            );
          },
        },
        {
          label: "创建时间",
          width: "160px",
          prop: "created_at",
        },
      ];
      return columns;
    },
  },
  mounted() {},
  created() {},
  methods: {
    // 初始化
    async initQuery() {
      this.tableLoading = true;
      let params = Object.assign({}, this.queryForm);
      params.page = this.nxpTabelAccessories.pageNo;
      params.page_size = this.nxpTabelAccessories.pageSize;
      params.project_id = this.project_id;
      if (this.userPhone) {
        this.searchConfigList[0].model = this.userPhone;
        params.phone = this.userPhone;
      }
      const { date } = params;
      if (date) {
        params.start_time = date[0];
        params.end_time = date[1];
        console.log("params", params, date);
        delete params.date;
      }
      queryReportDataList(params).then(({ code, data }) => {
        if (code == "200") {
          let pageData = (data && data.list) || [];
          this.tableData = pageData;
          this.nxpTabelAccessories.total = data.total_count;
        }
        this.tableLoading = false;
      });
    },

    // 分页查询
    fetchData(val) {
      this.nxpTabelAccessories.pageNo = val.pageNo;
      this.nxpTabelAccessories.pageSize = val.pageSize;
      this.initQuery();
    },
    // 模糊查询
    clickSearch(val) {
      this.queryForm = val;
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    resetForm() {
      this.queryForm = {};
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    handleEdit(row, action) {
      this.$refs["edit"].showEdit(row, action);
    },
  },
};
</script>
