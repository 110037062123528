<template>
  <div class="user-management-container">
    <nxp-search
      :config-list="searchConfigList"
      @clickSearch="clickSearch"
      @resetForm="resetForm"
    />
    <vab-query-form> </vab-query-form>
    <!-- 表格 -->
    <select-table
      :accessories="nxpTabelAccessories"
      only-key-id="id"
      :table-columns="tableColumns"
      :table-data="tableData"
      :table-loading="tableLoading"
      :w-theight="320"
      @fetchData="fetchData"
    />
    <el-dialog
      append-to-body
      class="common-dialog"
      :close-on-click-modal="false"
      :title="title"
      top="10vh"
      :visible.sync="dialogFormVisible"
      width="100vh"
      @close="close"
    >
      <div v-if="dialogFormVisible" class="abow_dialog">
        <!-- 苗单 -->
        <addTreeVarietyList
          v-if="dialogCom == 'varietyIndex'"
          ref="varietyIndex"
          :userPhone="userPhone"
        />
        <!-- 地图 -->
        <addMapList
          v-if="dialogCom == 'mapIndex'"
          ref="mapIndex"
          :userPhone="userPhone"
        />
        <!-- 报告 -->
        <addReportList
          v-if="dialogCom == 'reportIndex'"
          ref="reportIndex"
          :userPhone="userPhone"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryProjectDataList } from "@/project/eztree/baseApi/commonApi";
export default {
  name: "Project",
  components: {
    addTreeVarietyList: () => import("../TreeVariety/index"),
    addMapList: () => import("../Project/index"),
    addReportList: () => import("../Report/list"),
  },
  props: {
    userPhone: {
      type: String,
    },
  },
  data() {
    return {
      dialogCom: "",
      project_id: "",
      title: "",
      dialogFormVisible: false,
      queryForm: {},
      searchConfigList: [
        {
          type: "text",
          showName: false,
          model: "",
          param: "phone",
          label: "手机号",
        },
        {
          type: "text",
          showName: false,
          model: "",
          param: "project_name",
          label: "项目名称",
        },
        {
          type: "daterange",
          showName: false,
          model: "",
          param: "date",
          label: "日期",
        },
      ],
      tableLoading: false,
      tableData: [],
      nxpTabelAccessories: {
        pageType: "",
        pageNo: 1,
        pageSize: 15,
        total: 0,
        height: this.$baseTableHeight(1),
      },
    };
  },
  computed: {
    tableColumns() {
      let columns = [
        {
          label: "苗店头像",
          prop: "shop_avatar",
          width: "160px",
          align: "center",
          render: function (h, params) {
            return h("div", [
              h("img", {
                attrs: {
                  style: "height:100px;",
                  src: params.row.shop_avatar,
                  alt: "图片走丢了",
                },
              }),
            ]);
          },
        },
        {
          label: "苗店手机号",
          prop: "shop_phone",
          width: "160px",
        },
        {
          label: "苗店名称",
          prop: "shop_name",
          width: "160px",
        },

        { label: "项目名称", prop: "project_name" },
        { label: "设计单位", prop: "design_unit" },
        {
          label: "苗单个数",
          prop: "variety_len",
        },
        {
          label: "创建时间",
          width: "180px",
          prop: "created_at",
        },
        {
          label: "修改时间",
          width: "180px",
          prop: "updated_at",
        },
        // {
        //   label: "操作",
        //   width: "200px",
        //   render: (h, params) => {
        //     return h(
        //       "div",
        //       {
        //         style: {},
        //       },
        //       [
        //         h(
        //           "el-button",
        //           {
        //             attrs: {
        //               type: "text",
        //               params: params.row,
        //             },
        //             on: {
        //               ...this.$listeners,
        //               click: () => {
        //                 this.viewItemData(params.row, "varietyIndex", "苗单列表");
        //               },
        //             },
        //           },
        //           " 苗单列表"
        //         ),
        //         h(
        //           "el-button",
        //           {
        //             attrs: {
        //               type: "text",
        //               params: params.row,
        //             },
        //             on: {
        //               ...this.$listeners,
        //               click: () => {
        //                 this.viewItemData(params.row, "mapIndex", "地图列表");
        //               },
        //             },
        //           },
        //           "地图列表"
        //         ),
        //         h(
        //           "el-button",
        //           {
        //             attrs: {
        //               type: "text",
        //               params: params.row,
        //             },
        //             on: {
        //               ...this.$listeners,
        //               click: () => {
        //                 this.viewItemData(params.row, "reportIndex", "报告列表");
        //               },
        //             },
        //           },
        //           "报告列表"
        //         ),
        //       ]
        //     );
        //   },
        // },
      ];
      return columns;
    },
  },
  mounted() {},
  created() {},
  methods: {
    viewItemData(row, flag, action) {
      this.dialogCom = flag;
      this.userPhone = row.shop_phone;
      this.title = action;
      this.dialogFormVisible = true;
    },
    close() {
      this.dialogCom = "";
      this.userPhone = "";
      this.title = "";
      this.dialogFormVisible = false;
    },
    // 初始化
    async initQuery() {
      this.tableLoading = true;
      let params = Object.assign({}, this.queryForm);
      params.page = this.nxpTabelAccessories.pageNo;
      params.page_size = this.nxpTabelAccessories.pageSize;
      if (this.userPhone) {
        this.searchConfigList[0].model = this.userPhone;
        params.phone = this.userPhone;
      }
      const { date } = params;
      if (date) {
        params.start_time = date[0];
        params.end_time = date[1];
        console.log("params", params, date);
        delete params.date;
      }
      queryProjectDataList(params).then(({ code, data }) => {
        if (code == "200") {
          this.tableData = (data && data.list) || [];
          this.nxpTabelAccessories.total = data.total_count;
        }
        this.tableLoading = false;
      });
    },
    // 分页查询
    fetchData(val) {
      this.nxpTabelAccessories.pageNo = val.pageNo;
      this.nxpTabelAccessories.pageSize = val.pageSize;
      this.initQuery();
    },
    // 模糊查询
    clickSearch(val) {
      this.queryForm = val;
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    resetForm() {
      this.queryForm = {};
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    handleEdit(row, action) {
      this.$refs["edit"].showEdit(row, action);
    },
    viewItemData(row, flag, action) {
      this.dialogCom = flag;
      this.project_id = row.project_id;
      this.title = action;
      this.dialogFormVisible = true;
    },
    close() {
      this.dialogCom = "";
      this.project_id = "";
      this.title = "";
      this.dialogFormVisible = false;
    },
  },
};
</script>
